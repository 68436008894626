// Avatar.tsx
import React from "react";
import { Avatar as MUIAvatar } from "@mui/material";

interface AvatarProps {
  avatar: string; // Avatar URL
  altText: string; // Alt text for the avatar
  sx: { width: number, height: number, marginLeft: 1 }
}

const Avatar: React.FC<AvatarProps> = ({ avatar, altText, sx }) => {
  return (
    <MUIAvatar
      alt={altText}
      src={avatar}
      sx={sx}
    />
  );
};

export default Avatar;
