import axios from 'axios';

class HttpClient {
  private baseURL: string;

  constructor() {
    this.baseURL = "https://lc5tlu4pl2.execute-api.us-east-1.amazonaws.com/dev"//'http://localhost:80';
  }

  async get(djId: string) {
    try {
      // debugger;
      console.log(`${this.baseURL}${djId}`)
      const response = await axios.get(`${this.baseURL}?djId=${djId}`);
      return response.data.data; //{ddbItmes, photoUrl}
    } catch (error) {
      throw error;
    }
  }

  async post(url: string, data: any) {
    try {
      console.log(3333,data)
      const response = await axios.post(`${this.baseURL}${url}`,  data, { headers: {
        'Content-Type': 'application/json',
      }});
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async postPhoto(url: string, data: any) {
    try {
      console.log(9090,`${this.baseURL}${url}`)
      const response = await axios.post(`${this.baseURL}${url}/`, data);
      
      // { headers: {
      //   // 'Content-Type': 'multipart/form-data',
      //   // 'Content-Type': 'image/jpeg', // Make sure this matches the file type
      //   // 'Content-Length': data.size, // Specify the exact length

        
      // }}
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new HttpClient();
