import React from 'react';

type SoundCloudEmbedProps = {mainTrack: string}

const SoundCloudEmbed = (props: SoundCloudEmbedProps) => {
  return (
    <div style={{ width: '100%', height: '300px' }}>
      <iframe 
        width="100%" 
        height="300" 
        scrolling="no" 
        frameBorder="no" 
        allow="autoplay" 
        src= {props.mainTrack}>
      </iframe>
    </div>
  );
};

export default SoundCloudEmbed;
