import React from "react";
import { Grid, Card, CardContent, Typography, Tooltip, Box } from "@mui/material";
import Avatar from "../../Style/Avatar";
import hayimPhoto from "../../Photos/hayim.jpg";

// Sample data for events
const upcomingEvents = [
  {
    eventName: "Event 1",
    date: "2024-10-08",
    hoursRange: "10:00 AM - 12:00 PM",
    description: "This is a larger tooltip showing more details about Event 1. You can customize the tooltip size, padding, and style.",
    avatars: [
      "https://lh3.googleusercontent.com/ogw/AF2bZyhTxROIBwDDZK4RwBHk2Qm3S_dKooxsN4MFt3CmAKPXMw=s32-c-mo", 
      hayimPhoto,
      "https://via.placeholder.com/40",
    ],
  },
  {
    eventName: "Event 2",
    date: "2024-10-09",
    hoursRange: "1:00 PM - 3:00 PM",
    description: "This tooltip also has extra padding and a larger font size for better readability.",
    avatars: [
      "https://lh3.googleusercontent.com/ogw/AF2bZyhTxROIBwDDZK4RwBHk2Qm3S_dKooxsN4MFt3CmAKPXMw=s32-c-mo", 
      hayimPhoto,
    ],
  },
  // Add more events here
];

interface Section2Props {
  cardWidth: number;
  cardHeight: number;
}

const Section2: React.FC<Section2Props> = ({ cardWidth, cardHeight }) => {
  return (
    <Grid container spacing={2} sx={{ overflowX: "auto", flexWrap: "nowrap" }}>
      {upcomingEvents.map((event, index) => (
        <Grid item key={index} sx={{ minWidth: cardWidth }}>
          <Tooltip
            title={
              <div style={{ fontSize: "1.2rem", padding: "16px", maxWidth: "300px" }}>
                <Typography variant="body1"><strong>More Info:</strong></Typography>
                <Typography variant="body2">{event.description}</Typography>
              </div>
            }
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#f5f5f5", // Tooltip background color
                  color: "black", // Tooltip text color
                  padding: "16px", // Increase padding for a larger tooltip
                  fontSize: "1.2rem", // Bigger font size
                  maxWidth: "300px", // Control the width of the tooltip
                },
              },
              arrow: {
                sx: {
                  color: "#f5f5f5", // Arrow color to match tooltip background
                },
              },
            }}
          >
            <Card
              sx={{
                width: cardWidth,
                height: cardHeight,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": { backgroundColor: "#f5f5f5" }, // Hover effect
              }}
            >
              <CardContent>
                <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
                  {event.eventName}
                </Typography>
                <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                  {event.date}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center" }}>
                  {event.hoursRange}
                </Typography>

                {/* Avatars list mapped here */}
                <Box mt={2} display="flex" justifyContent="center">
                  {event.avatars.map((avatar, avatarIndex) => (
                    <Avatar key={avatarIndex} avatar={avatar} altText={`Person ${avatarIndex + 1}`} sx={{ width: 30, height: 30, marginLeft: 1 }}/>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default Section2;
