import { Grid, Box, Typography, Card, CardMedia, CardContent } from "@mui/material";
import Header from "./Header";
import Section2 from "./Section2";
import djicon from "../../Photos/djIcon2.png";
import Avatar from "../../Style/Avatar";
import TopDj from "./TopDj";

// Sample list of top DJs
const topDjs = [
  {
    name: "hayim",
    description: "Exciting DJ bringing people together through events.",
    avatar: "https://via.placeholder.com/40",
    image: "https://via.placeholder.com/150", // Replace with actual image
  },
  {
    name: "DJ Name 2",
    description: "Renowned DJ known for hosting great events.",
    avatar: "https://via.placeholder.com/40",
    image: "https://via.placeholder.com/150", // Replace with actual image
  },
  {
    name: "DJ Name 3",
    description: "International DJ creating thrilling experiences.",
    avatar: "https://via.placeholder.com/40",
    image: "https://via.placeholder.com/150", // Replace with actual image
  },
];

function MainPage() {
  return (
    <Box>
      {/* Full-length Header */}
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
      </Grid >

      <Grid item xs={12} margin={4}>
      <Typography variant="h4" component="div" sx={{ textAlign: "center"  }}>
      Welcome to DafShel - your page for everyone      </Typography>
        </Grid>

      {/* Sections */}
      <Grid container spacing={2} justifyContent="center">
        
        {/* First Section: Photo and Description */}
        <Grid item xs={12} md={3} marginTop={2}>
              <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
                About Us
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                We are a leading platform for upcoming events, providing a wide range of events tailored to different
                interests.
                We are a leading platform for upcoming events, providing a wide range of events tailored to different
                interests.
              </Typography>
        </Grid>

        {/* Second Section: Another Photo and Description */}
        <Grid item xs={12} md={3} marginTop={2}>
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            Our Mission
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
            To bring people together through diverse and exciting events happening in your area.
          </Typography>
        </Grid>

        {/* Upcoming Events Section: Smaller Cards in Horizontal Scroll */}
        <Grid item xs={12} margin={2} bgcolor="#d4d4d4" >
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            Upcoming Events
          </Typography>

          <Grid container spacing={2} sx={{ overflowX: "auto", flexWrap: "nowrap", justifyContent: "center" }}>
          <Section2 cardWidth={200} cardHeight={200} />
          </Grid>
        </Grid>

        {/* Top DJ's Section: Horizontal Scroll */}
        <Grid item xs={12} marginTop={4}>
          {/* <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            Top DJ's
          </Typography> */}

 {/* Top DJs Section */}
 <Grid item xs={12}  bgcolor="#d4d4d4" >
 <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
TopDj's          </Typography>        <TopDj cardWidth={180} cardHeight={220} /> {/* You can adjust dimensions */}
      </Grid>

   
        </Grid>
      </Grid>
    </Box>
  );
}

export default MainPage;
