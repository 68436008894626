import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const StyledEventSection: React.FC<{ event: any }> = ({ event }) => {
  return (
    <Box
      sx={{
        border: '2px solid #ddd', // Solid border around the section
        borderRadius: '8px', // Rounded corners
        padding: '16px', // Inner padding
        backgroundColor: '#E8DFDF', // Light background color for contrast
        maxWidth: '400px', // Optional: Set a maximum width for the section
        margin: '0 auto', // Center the box if needed
        textAlign: 'center', // Center the text
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
      }}
    >
      {/* Event Date and Icon */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <CalendarTodayIcon sx={{ mr: 1 }} />
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {event.date}
        </Typography>
      </Box>

      {/* Event Hours */}
      <Typography variant="body2" gutterBottom>
        {event.hoursRange}
      </Typography>

      {/* Event Name */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
        {event.eventName}
      </Typography>

     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
        {event.location}
      </Typography>
    </Box>
  );
};

export default StyledEventSection;
