import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserComponent from './Components/User/User';
import AddUserComponent from './Components/AddUser/AddUserComponent';
import MainPage from './Components/MainPage/MainPage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Define a route for the home page */}
        <Route path="/" element={<MainPage />} />
        
        {/* Route for dynamic user pages with userId */}
        <Route path="/user/:userId" element={<UserComponent />} />
        
        {/* Route for adding a new user */}
        <Route path="/add" element={<AddUserComponent />} />
      </Routes>
    </Router>
  );
};

export default App;
