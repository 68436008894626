import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, Box, Paper, Grid, Chip, CircularProgress, IconButton, Button } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SoundCloudIcon from '@mui/icons-material/CloudQueue'; // Example for SoundCloud Icon
import HttpClient from '../../Logic/HttpClient';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import djicon from '../../Photos/djIcon2.png';
import EventDialog from '../../Style/EventDialog';
import StyledEventSection from '../../Style/StyledEvent';
// import InfoToolTip from '../InfoToolTip';
import EventIcon from '@mui/icons-material/Event';
import SoundCloudEmbed from '../../Style/SoundCloudEmbed';

interface UserData {
  name: string;
  nickname: string;
  description: string;
  styles: string[];
  date: string;
  photo: string | null;
  photoUrl: string | null;
  // socialLinks: string[]; // Ensure this is defined as an array
  socialLinks: {
    instagram: string;
    facebook: string;
    soundcloud: string; 
    youtube: string;
  },
  upcomingEvents: { date: string, hoursRange: string, eventName: string }[],
  mainTrack: string;
}

const UserComponent: React.FC = () => {
  const { userId } = useParams<{ userId: string }>(); // Extract userId from URL
  const location = useLocation(); // Used to access state if passed from form
  const [user, setUser] = useState<UserData | null>(location.state?.user || null);
  const [loading, setLoading] = useState<boolean>(!user); // Loading state (skip loading if user is passed from state)

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // {eventName, location}

  const handleOpenDialog = (event: any) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  
  useEffect(() => {
    // Only fetch from backend if no user data is passed via location.state
    if (!user) {
      const fetchUserData = async () => {
        setLoading(true);
        try {
          // Replace with actual API call
          const response = await HttpClient.get(`${userId}`);
          const userState = response.ddbItems[0];
          // const response = mockDjs['hayim-sarfati'];
          userState.photoUrl = response.photoUrl;
          // {console.log(677554,userState)}

          setUser(userState); // Assuming the response contains user data
          // setUser(response[0]);
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    }
  }, [userId, user]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Typography variant="h6">User not found.</Typography>;
  }

  return (
    <Grid container spacing={2} justifyContent="center" bgcolor={"#7D7777"}>
      {/* Full-width header */}
      <Grid item xs={12}>
        <Box bgcolor="#3A3A38" color="white" p={2} textAlign="center">
          <Typography variant="h4">{user.name}</Typography>
        </Box>
      </Grid>

      {/* Main content */}
      <Grid item xs={12} md={10}>
        <Paper sx={{ padding: 3, backgroundColor:"#918D8D" }}>
          <Grid container spacing={2}>
            {/* User photo section */}
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  width: 350, // Fixed width for circular placeholder
                  height: 350, // Fixed height for circular placeholder
                  borderRadius: '50%', // Circular shape
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '2px solid #ddd', // Optional border
                }}
              >
                <img
                  // src={hayimPic || ''}
                  src={user.photoUrl || ''}
                  alt={user.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensure the image covers the entire container while maintaining aspect ratio
                  }}
                />
              </Box>
            </Grid>

            {/* User information section */}
            <Grid item xs={12} md={7}>
              {/* User description */}
              <Typography variant="body1" gutterBottom>
                {user.description || "This is a sample description for the user."}
              </Typography>

              {/* Tags (Styles) */}
              <Box my={2}>
                <Typography  variant="h6" gutterBottom style={{ fontWeight: 'bold'}}>
                {/* <img src={djicon}  alt="Description Icon" style={{ width: '80px', marginLeft: '10px' }} /> */}
                  Genres
                  </Typography>

                <Box display="flex" flexWrap="wrap" gap={1}>
                  {user.styles.map((style: string) => (
                    <Chip key={style} label={style} color="primary" />
                  ))}
                </Box>
              </Box>

              {/* Social Icons */}
              <Box my={2}>
                <Typography variant="h6" style={{ fontWeight: 'bold'}} gutterBottom>Connect</Typography>
                <Box display="flex" gap={2}>
                  {user.socialLinks.instagram && (
                    <IconButton aria-label="instagram" href={user.socialLinks.instagram} target="_blank" sx={{ color: '#E4405F' }}>
                     {/* <IconButton aria-label="instagram" href={mockDjs['hayim-sarfati'].socialLinks.instagram} target="_blank" sx={{ color: '#E4405F' }}> */}
                      <InstagramIcon />
                    </IconButton>
                  )}
                  {user.socialLinks.youtube && (
                    <IconButton aria-label="youtube" href={user.socialLinks.youtube} target="_blank" sx={{ color: '#FF0000' }}  >
                      <YouTubeIcon />
                    </IconButton>
                  )}
                  {user.socialLinks.soundcloud && (
                    <IconButton aria-label="soundcloud" href={user.socialLinks.soundcloud} target="_blank" sx={{ color: '#FF5500' }}  >
                      <SoundCloudIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
              <SoundCloudEmbed mainTrack={user.mainTrack}></SoundCloudEmbed>
          </Grid>
          
          </Grid>
          <Box mt={4}>



      <Typography variant="h4" gutterBottom>
  <EventIcon style={{ color: '#673ab7', marginRight: '8px' }} />
  Upcoming Events
</Typography>

      <Grid container spacing={2} wrap="nowrap" overflow="auto" marginTop={2}>
        {user.upcomingEvents.map((event, index) => (
          <Grid
            key={index}
            item
            sx={{
              padding: 2,
              borderRadius: 2,
              minWidth: '240px',
              textAlign: 'center',
              marginRight: '16px',  // Adding margin between event boxes
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                backgroundColor: '#f0f0f0',
              },
            }}
            onClick={() => handleOpenDialog(event)} // Full-screen dialog on click
          >
            {/* Use the StyledEventSection for rendering the event details */}
            <StyledEventSection event={event} />
          </Grid>
        ))}
      </Grid>

      {/* Full-Screen Dialog */}
      {selectedEvent && (
        <EventDialog
          open={openDialog}
          onClose={handleCloseDialog}
          event={selectedEvent}
        />
      )}
    </Box>


          {/* Full-width section for date details */}
          <Grid container spacing={2}>

          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserComponent;
