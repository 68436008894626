// TopDj.tsx
import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from "../../Style/Avatar";
import hayimPhoto from "../../Photos/hayim.jpg";

// Sample data for DJs
const topDjs = [
  {
    userId: "hayim", // Unique user ID for routing
    name: "hayim",
    genre: "Electronic",
    avatars: [
      hayimPhoto,
    ],
  },
  {
    userId: 2,
    name: "sap",
    genre: "Hip Hop",
    avatars: [
      "https://lh3.googleusercontent.com/ogw/AF2bZyhTxROIBwDDZK4RwBHk2Qm3S_dKooxsN4MFt3CmAKPXMw=s32-c-mo",
    ],
  },
  // Add more DJs here
];

interface TopDjProps {
  cardWidth: number;
  cardHeight: number;
}

function TopDj({ cardWidth, cardHeight }: TopDjProps) {
  const navigate = useNavigate();

  const handleCardClick = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  return (
    <Grid container spacing={2} direction="row" marginTop={1}>
      {topDjs.map((dj, index) => (
        <Grid item key={index}>
          <Card
            sx={{
              width: cardWidth,
              height: cardHeight,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              cursor: "pointer",
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
            onClick={() => handleCardClick(dj.name)} // Navigate to user's profile on click
          >
            <CardContent>
              <Typography variant="h5" component="div">
                {dj.name}
              </Typography>
              <Typography color="text.secondary">
                {dj.genre}
              </Typography>

              {/* Avatars list mapped here */}
              <Box mt={2} display="flex">
                {dj.avatars.map((avatar, avatarIndex) => (
                  <Avatar key={avatarIndex} avatar={avatar} altText={`DJ ${avatarIndex + 1}`} sx={{ width: 60, height: 60, marginLeft: 1 }} />
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default TopDj;
