import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, AppBar, Toolbar, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface EventDialogProps {
  open: boolean;
  onClose: () => void;
  event: any; // Adjust the type according to your event object
}

const EventDialog: React.FC<EventDialogProps> = ({ open, onClose, event }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {event.eventName}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="h4" gutterBottom>
          {event.eventName}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Date: {event.date}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Time: {event.hoursRange}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Details: {event.description || 'No additional details available.' }
        </Typography>
        <Typography variant="body1">
          Location: {event.location || 'Unknown'}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default EventDialog;
