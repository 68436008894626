import React, { useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, Box, MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import HttpClient from '../../Logic/HttpClient';

const AddUserComponent: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    styles: [] as string[],
    date: '',
    photo: null as File | null,
    photoUrl: "",
    DjId: "",
    slugId: "",
    firstName: ""
  });

  const stylesOptions = ["Electronic", "Country", "Rock",  "Disco", "Hip Hop"];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleStylesChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      styles: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      console.log(55,e.target.files[0])
      setFormData({
        ...formData,
        photo: e.target.files[0],
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data = new FormData();
    data.append('name', formData.name);
    data.append('description', formData.description);
    data.append('styles', formData.styles.join(',')); // Convert array to comma-separated string
    data.append('date', formData.date);
    data.append('DjId', formData.DjId); // Note: Ensure the field name matches the backend's expected name
    data.append('SlugId', formData.slugId);
    

    
// console.log(777,formData.photo);
    if (formData.photo) {
      data.append('photo', formData.photo);
      await HttpClient.postPhoto("/dj/photo",formData)
    }

    try {
      // Send request to backend
      const response = await HttpClient.post(`/dj/set`, formData);

      console.log(555,response)

      // Assume response contains the new user data, including `djId`
      const newUserData = response;

      // Update formData state with the response data
      setFormData({
        ...formData,
        name: newUserData.name,
        description: newUserData.description,
        styles: newUserData.styles,
        photo: newUserData.photo, // Assuming the backend provides a reference or URL to the uploaded photo
        DjId: newUserData.DjId,   // Use the djId returned from the backend
      });

      console.log(555,response)

      // Navigate using the new `djId` from the response
      
      navigate(`/user/${newUserData.DjId}`, { state: { user: newUserData } });

    } catch (error) {
      console.error('Error submitting form:', error);
    }

    // Example upload function in the frontend
// const uploadPhotoToS3 = async (file: File) => {
//   const formData = new FormData();
//   formData.append('file', file);

//   try {
//     const response = await HttpClient.postPhoto('/upload-photo', formData);

//     return response.data.fileUrl; // This should be the URL of the uploaded photo
//   } catch (error) {
//     console.error('Error uploading photo:', error);
//     throw error;
//   }
// };

  };

  const handleSubmit2 = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Upload photo to S3 if present
    let photoUrl = '';
    if (formData.photo) {
      try {
        photoUrl = await uploadPhotoToS3(formData.photo);
      } catch (error) {
        console.error('Error uploading photo:', error);
        return; // Exit the function if photo upload fails
      }
    }
  
    // Prepare form data to send to the backend (including the photo URL)
    const data = new FormData();
    data.append('name', formData.name);
    // data.append('nickname', formData.nickname);
    data.append('styles', formData.styles.join(',')); // Convert array to comma-separated string
    data.append('date', formData.date);
    data.append('DjId', formData.DjId); // Make sure this matches backend expectations
    data.append('SlugId', formData.slugId);
    if (photoUrl) {
      data.append('photoUrl', photoUrl); // Add the S3 photo URL to the form data
    }
  
    try {
      // Send request to backend with the form data
      const response = await HttpClient.post(`/dj/set`, data);
  
      const newUserData = response.data;
  
      // Update formData state with the response data (including new DjId and photo URL)
      setFormData({
        ...formData,
        name: newUserData.name,
        description: newUserData.description,
        styles: newUserData.styles,
        photo: newUserData.photoUrl, // Assuming the backend sends the photo URL back
        DjId: newUserData.djId,       // Backend should return the new djId
      });
  
      // Navigate to the new user's page using the returned djId
      navigate(`/user/${newUserData.djId}`, { state: { user: newUserData } });
  
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // const handleSubmitNew= async (e: React.FormEvent) => {
  //   e.preventDefault();

  //   // Create FormData object to send to backend
  //   const data = new FormData();
  //   data.append('name', formData.name);
  //   data.append('description', formData.description);
  //   data.append('styles', formData.styles.join(',')); // Convert array to comma-separated string
  //   data.append('date', formData.date);
  //   data.append('DjId', formData.DjId);
  //   data.append('SlugId', formData.slugId);
  //       if (formData.photo) {

  //   data.append('photo', formData.photo);
  //       }
    

  //   let photoUrl = '';
  //   // if (formData.photo) {
  //   //   try {
  //   //     // photoUrl = await uploadPhotoToS3(formData.photo);
  //   //   } catch (error) {
  //   //     console.error('Error uploading photo:', error);
  //   //     return; // Exit the function if photo upload fails
  //   //   }
  //   // }

  //   try {
  //     // Send the form data to the backend
  //     const response = await HttpClient.post(`/dj/set`, formData); // todo check data

  //     // Extract user data from the response
  //     const newUserData = response.data;
  //     console.log(response.data, newUserData)

  //     // Update formData state with the returned data from the backend
  //     setFormData({
  //       ...formData,
  //       name: newUserData.name,
  //       description: newUserData.description,
  //       styles: newUserData.styles,
  //       DjId: newUserData.DjId,
  //       photoUrl: newUserData.photoUrl, // S3 URL returned from the backend
  //     });


  //     // Navigate to the user's profile page using the new DjId
  //     navigate(`/user/${newUserData.DjId}`, { state: { user: newUserData } });
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //   }
  // };
  
  // Example upload function to handle S3 photo upload
  const uploadPhotoToS3 = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await HttpClient.postPhoto(`/dj/upload/photo/sap`, formData);
  
      return response.data.fileUrl; // Return the S3 URL of the uploaded photo
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error;
    }
  };
  

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', backgroundColor: '#f0f0f0' }}>
      <Grid item xs={12} sm={6} md={4}>
        <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'white' }}>
          <Typography variant="h5" gutterBottom>
            Add New DJ
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="normal"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              label="FirstName"
              name="firstName"
              fullWidth
              margin="normal"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            <TextField
              label="DjId"
              name="DjId"
              fullWidth
              margin="normal"
              value={formData.DjId}
              onChange={handleChange}
              required
            />
            <TextField
              label="SlugId"
              name="slugId"
              fullWidth
              margin="normal"
              value={formData.slugId}
              onChange={handleChange}
              required
            />
                        <TextField
              label="Description"
              name="description"
              fullWidth
              margin="normal"
              value={formData.description}
              onChange={handleChange}
              required
            />


            {/* Styles Multiple Select */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Styles</InputLabel>
              <Select
                label="Styles"
                name="styles"
                multiple
                value={formData.styles}
                onChange={handleStylesChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
              >
                {stylesOptions.map((style) => (
                  <MenuItem key={style} value={style}>
                    <Checkbox checked={formData.styles.indexOf(style) > -1} />
                    <ListItemText primary={style} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Date"
              name="date"
              fullWidth
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.date}
              onChange={handleChange}
              // required
            />

            {/* Photo upload */}
            <Box mt={2}>
              <input
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
              />
            </Box>

            <Box mt={2}>
              <Button type="submit" variant="contained" fullWidth>
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddUserComponent;
